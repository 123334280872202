import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

  const routes = [

    { //首页
      path: '/',
      name: 'home',
      component: () => import('../views/home.vue'),
      children: [
        { //首页
          path: '/',
          name: 'Index',
          component: () => import('../views/content/Index.vue'),
        },
        { //个人中心-通知设置
          path: '/settings',
          name: 'Settings',
          component: () => import('../views/content/Settings.vue')
        },
        { //个人中心-我的证书
          path: '/mycertificate',
          name: 'Mycertificate',
          component: () => import('../views/content/Mycertificate.vue')
        },
        { //个人中心-消息中心
          path: '/message',
          name: 'Message',
          component: () => import('../views/content/Message.vue')
        },
        { //个人中心-账号信息
          path: '/basicinfor',
          name: 'Basicinfor',
          component: () => import('../views/content/Basicinfor.vue')
        },
        { //个人中心-主页
          path: '/personal',
          name: 'Personal',
          component: () => import('../views/content/Personal.vue'),
          meta:{ GuidePath: true, JumpPath:'/personal'}
        },
        { //关于我们-关于我们
          path: '/aboutus',
          name: 'Aboutus',
          component: () => import('../views/content/Aboutus.vue')
        },
        { //关于我们-使用说明
          path: '/direction',
          name: 'Direction',
          component: () => import('../views/content/Direction.vue')
        },
        { //关于我们-用户协议
          path: '/protocol',
          name: 'Protocol',
          component: () => import('../views/content/Protocol.vue')
        },
        { //关于我们-隐私协议
          path: '/privacy',
          name: 'Privacy',
          component: () => import('../views/content/Privacy.vue')
        },
        { //课程列表
          path: '/courselist/:id?/:name?',
          name: 'Courselist',
          component: () => import('../views/content/Courselist.vue')
        },
        { //课程详情
          path: '/details/:id?',
          name: 'Details',
          component: () => import('../views/content/Details.vue')
        },
        { //注册
          path: '/register',
          name: 'Register',
          component: () => import('../views/content/Register.vue')
        },
        { //评价
          path: '/evaluate/:num/:keid/:zjid/:xjid?',
          name: 'Evaluate',
          component: () => import('../views/content/Evaluate.vue')
        },
        { //申请证书
          path: '/certificate/:id?',
          name: 'Certificate',
          component: () => import('../views/content/Certificate.vue')
        },
        { //考试
          path: '/examination/:kcid/:sjid?',
          name: 'Examination',
          component: () => import('../views/content/Examination.vue')
        },
        { //考试成绩
          path: '/result/:kcid/:sjid?',
          name: 'Result',
          component: () => import('../views/content/Result.vue')
        },{ //注销
          path: '/cancellation',
          name: 'Cancellation',
          component: () => import('../views/content/Cancellation.vue')
        },{ //忘记密码页
          path: '/forgotpassword',
          name: 'Forgotpassword',
          component: () => import('../views/content/Forgotpassword.vue')
        }
      ]
    },
    { //登录页面
      path: '/login',
      name: 'login',
      component: () => import('../views/login.vue')
    },
  ]


const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savePosition) {
    // return 期望滚动到哪个的位置
    return {
      y: 0,
    };
  },
})

export default router
