<template>
  <div class="Hearder-pf">
    <div class="Hearder-box">
      <div class="Hearder-left">
        <div class="Hearder-logo">
          <img src="../../public/images/logo.png">
        </div>
        <div class="Hearder-title">
          <span class="text_1">中国气象行业慕课</span>
          <span class="text_2">CMA-MOOC</span>
        </div>

        <div class="Hearder-list">
          <router-link to="/" :class="[{ on: tTitle==1 }, 'around']">HOME</router-link>

          <el-dropdown :class="[{ on: tTitle==2 }, 'Hearder-down']" @command="handleCommand">
            <span class="el-dropdown-link" @click="dhgoto()">
              COURSE<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="item.id" v-for="(item, index) in pxly" :key="index">{{item.name}}</el-dropdown-item>
              <!-- <el-dropdown-item command="b">HOME</el-dropdown-item>
              <el-dropdown-item command="c">HOME</el-dropdown-item>
              <el-dropdown-item command="d">HOME</el-dropdown-item>
              <el-dropdown-item command="e">HOME</el-dropdown-item> -->
            </el-dropdown-menu>
          </el-dropdown>
          
          <router-link to="/aboutus" :class="[{ on: tTitle==3 }, 'around']">ABOUT</router-link>
        </div>
      </div>
      

      <div class="Hearder-right">
        <div class="Hearder-So">
          <el-input v-model="cont" @keyup.enter.native="toFilterData" prefix-icon="el-icon-search" placeholder="请输入内容"></el-input>
        </div>

        <!-- <div class="Hearder-sele">
          <el-dropdown>
            <span class="el-dropdown-link">
              中文<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>中文</el-dropdown-item>
              <el-dropdown-item>中国</el-dropdown-item>
              <el-dropdown-item>中国</el-dropdown-item>
              <el-dropdown-item>中国</el-dropdown-item>
              <el-dropdown-item>中国</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div> -->

        <router-link to="/personal" class="Hearder-toux" v-if="token">
          <el-badge is-dot :hidden = true class="item">
            <img src="../../public/images/getx.png">
          </el-badge>
        </router-link>

        <router-link to="/login" class="Hearder-login" v-if="!token">login</router-link>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AllHeader',
    data(){
        return{
          token: localStorage.pctoken,  //获取token
          tTitle: 0,
          cont:'',
          pxly:[]
        }
    },
    created(){
      this.bbtn();
      this.getpxly();
    },
    methods:{
      bbtn(){
        this.bus.$on('tTitle',(tTitle) => {this.tTitle = tTitle})
      },

      //获取培训领域
      getpxly(){
        this.$http.post('common/classify',{
          alias: 3
        },{}).then((res)=>{
          if(res.data.code == 0){
            this.pxly = res.data.data;
            
          }else{
            this.$layer.msg(res.data.msg);
          }
        })
      },
      
      dhgoto(){
        this.$router.push({name:'Courselist'});
      },
      handleCommand(command) {
        this.$router.push({name:'Courselist',params:{id:command}});
      },
      toFilterData(){
        const router = this.$route.path;
        if(this.cont){
          localStorage.setItem('solai', this.cont);
        }else{
          localStorage.removeItem('solai')
        }

        if(router != '/courselist'){
          this.$router.push({name:'Courselist'});
        }else{
          location.reload();
        }
        this.cont = ''
      }
    }
  }
</script>

<style scoped>
  .Hearder-logo img{
    width: 47px;
    height: 47px;
  }

  .Hearder-pf{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 444;
  }
  .Hearder-box{
    background: rgba(0, 68, 131, 1);
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    
  }
  .Hearder-left{
    display: flex;
    align-items: center;
  }
  .Hearder-title{
    margin-left: 8px;
  }
  .text_1 {
    display: block;
    color: rgba(255, 255, 255, 1);
    font-size: 20px;
    font-family: MicrosoftYaHei-Bold;
    font-weight: 700;
  }

.text_2 {
  display: block;
  color: rgba(190, 158, 111, 1);
  font-size: 14px;
  font-family: ArialMT;
  margin-top: 3px;
}
.Hearder-list{
  display: flex;
  align-items: center;
}
.Hearder-list a{
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-family: Arial-BoldMT;
  margin: 0 50px;
  border-top: 3px solid rgba(0, 68, 131, 1);
  line-height: 67px;
}
.Hearder-list a:hover{
  border-top: 3px solid rgba(247, 174, 12, 1);
  line-height: 67px;
}
.Hearder-list .on{
  border-top: 3px solid rgba(247, 174, 12, 1);
  line-height: 67px;
}
.Hearder-down{
  cursor: pointer;
}
.Hearder-down{
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-family: Arial-BoldMT;
  border-top: 3px solid rgba(0, 68, 131, 1);
  line-height: 67px;
}
.Hearder-down:hover{
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-family: Arial-BoldMT;
  border-top: 3px solid rgba(247, 174, 12, 1);
  line-height: 67px;
}
.Hearder-right{
  display: flex;
  align-items: center;
}
::v-deep .Hearder-So .el-input__inner{
  border-radius: 30px;
}

.Hearder-sele span{
  cursor: pointer;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-family: ArialMT;
  background: url('../../public/images/english.png') left center no-repeat;
  padding-left: 26px;
  margin-left: 47px;
}
.Hearder-toux{
  cursor: pointer;
  width: 27px;
  height: 27px;
  margin-left: 23px;
}
.Hearder-toux img{
  width: 27px;
  height: 27px;
}

.Hearder-login{
  width: 96px;
  height: 40px;
  background: #F7AE0C;
  border-radius: 20px;
  font-size: 18px;
  font-family: ArialMT;
  color: #FFFFFF;
  line-height: 38px;
  text-align: center;
  margin-left: 20px;
}
::v-deep .el-dropdown-menu{
  max-height: 300px;
  overflow: auto;
}
</style>
