import axios from 'axios';
import Elementui from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en'; // lang i18n
import 'element-ui/lib/theme-chalk/index.css';
import 'swiper/dist/css/swiper.min.css';
import 'swiper/dist/js/swiper.min';
import Vue from 'vue';
import VueDPlayer from 'vue-dplayer';
import 'vue-dplayer/dist/vue-dplayer.css';
import layer from 'vue-layer';
import 'vue-layer/lib/vue-layer.css';
import Router from 'vue-router';
import App from './App.vue';
import domMessage from './messageOnce';
import router from './router';
import store from './store';
 
Vue.use(VueDPlayer);


// import * as Quill from 'quill'
// import { ImageDrop } from 'quill-image-drop-module'
// Quill.register('modules/imageDrop', ImageDrop)

// import ImageResize from 'quill-image-resize-module'
// Quill.register('imageResize', ImageResize)

Vue.config.productionTip = false
Vue.prototype.$layer = layer(Vue,{msgtime:6});

Vue.prototype.$http = axios;

// set ElementUI lang to EN 
Vue.use(Elementui,{ locale })
// 中文
// Vue.use(Elementui)

let bus = new Vue()
Vue.prototype.bus = bus;

Vue.use(Router)

// 正式 http://api.rtcbj.cma.cn
//upload url地址 https://api.muke.bailingkeji.com
Vue.prototype.$uploadUrl = 'http://api.rtcbj.cma.cn';
//统一接口域名
// if(window.location.host == 'localhost:8080'){
  axios.defaults.baseURL='https://api.muke.bailingkeji.com/web/';
// }else{
//   axios.defaults.baseURL='http://api.rtcbj.cma.cn/web/';
// }

const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const messageOnce = new domMessage()

axios.interceptors.response.use(
  response => {
    //当返回信息为未登录或者登录失效的时候重定向为登录页面
    if (response.data.code == 4001 || response.data.code == 4003 || response.data.code == 4004 || response.data.code == 4005 || response.data.code == 'Token过期' || response.data.code == 'Token无效') {
        
        messageOnce.warning({
          message: 'Login failed, please log in again',
          type: 'warning',
          duration:'1500',
          center: true
        })

        setTimeout(() => {
          router.push({
            path: "/login",
            querry: { redirect: router.currentRoute.fullPath }//从哪个页面跳转
          })
        }, 1500);
        
    }
    return response;
  },
  error => {
    return Promise.reject(error)
  }
)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
