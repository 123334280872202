<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  import AllHeaders from './components/Header.vue';//公用头部
  import BotToms from './components/Bottom.vue';//公用底部

  export default {
    name:'app',
    components:{
      AllHeaders,
      BotToms
    },
    provide () {
      return {
        reload: this.reload
      }
    },
    data(){
      return{
        isRouterAlive:true
      }
    },
    methods: {
      reload(){
        this.isRouterAlive = false
        this.$nextTick(function(){
            this.isRouterAlive = true
        })
      }
    },
    
  }
</script>

<style>
  html,body,#app,.el-container{
    padding: 0px;
    margin: 0px;
    height: 100%;
    font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  }

  a{
    text-decoration:none
  }

  li{
    list-style-type:none;
  }
</style>
