<template>
  <div class="Bottom-new" ref="gkl1">
    <div class="Bottom-box">

    <div class="Bottom-top">
      <div class="Bottom-logo">
        <img src="../../public/images/logo.png">
      </div>
      <div class="Bottom-title">
        <span class="text_1">中国气象行业慕课</span>
        <span class="text_2">CMA-MOOC</span>
      </div>
    </div>

    <div class="Bottom-cent">

      <div class="Bottom-left">
        <div class="Bottom-text">{{info.content}}</div>
      </div>

      <div class="Bottom-right">
        <div class="Bottom-conus">Contact Us</div>
        <div class="Bottom-every"><span>Address: </span>{{info.address}}</div>
        <div class="Bottom-every"><span>Tel: </span>{{info.mobile}}</div>
        <div class="Bottom-every"><span>Email: </span>{{info.email}}</div>
        <div class="Bottom-every"><span>Postal code: </span>{{info.zip_code}}</div>
      </div>

    </div>
    </div>

    <div class="Bottom-copyright">版权所有：中国气象局气象干部培训学院</div>
  </div>
  
</template>

<script>
  export default {
    name: 'BotTom',
    data(){
      return{
        info:[],
      }
    },
    created(){
      this.$http.post('home/us',{},{}).then((res)=>{
        if(res.data.code == 0){
          this.info = res.data.data;
        }else{
          this.$layer.msg(res.data.msg);
        }
      })
    },
    methods: {},
  }
</script>

<style scoped>
  .Bottom-box{
    min-width: 1200px;
    height: 377px;
    background: #004483;
    margin-top: 63px;
    padding: 50px 0 32px;
  }
  .Bottom-cent{
    width: 1200px;
    margin: 20px auto 0;
    display: flex;
  }
  .Bottom-top{
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
  .Bottom-logo{
    width: 71px;
    height: 71px;
  }
  .Bottom-logo img{
    width: 71px;
    height: 71px;
  }
  .Bottom-title{
    margin-left: 8px;
  }
  .text_1 {
    display: block;
    color: rgba(255, 255, 255, 1);
    font-size: 28px;
    font-family: MicrosoftYaHei-Bold;
    font-weight: 700;
  }
  .text_2 {
    display: block;
    color: rgba(190, 158, 111, 1);
    font-size: 18px;
    font-family: ArialMT;
    margin-top: 3px;
  }
  .Bottom-text{
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #FFFFFF;
    line-height: 26px;
  }
  .Bottom-left{
    flex: 1;
    padding-right: 40px;
    border-right: 1px solid #FAFAFA;
  }
  .Bottom-right{
    padding-left: 40px;
  }
  .Bottom-conus{
    font-size: 24px;
    font-family: Arial-BoldMT, Arial;
    color: #FFFFFF;
    margin-bottom: 20px;
  }
  .Bottom-every{
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #fff;
    line-height: 22px;
  }
  .Bottom-every span{
    color: #F7AE0C;
  }
  .Bottom-copyright{
    min-width: 1200px;
    line-height: 50px;
    background: #282828;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #F2F2F2;
    text-align: center;
  }
</style>
